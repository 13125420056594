<template>
  <div id="notsafeforwork1">
    <div class="container">
      <age-restriction v-if="RestrictionForm" 
        @click="Next('ContentPreferenceForm')"
        :mode="Mode"
        :type="Type"
      ></age-restriction>
      <content-preference :mode="Mode" :type="Type" v-if="ContentPreferenceForm" @click="Next('FantasyPreferenceForm')"></content-preference>
      <fantasy-preference :mode="Mode" :type="Type" v-if="FantasyPreferenceForm" @click="Next('ApprovalForm')"></fantasy-preference>
      <approval :mode="Mode" :type="Type" v-if="ApprovalForm" @click="OpenSite"></approval>
    </div>
  </div>
</template>
<script>
import AgeRestriction from '@/sections/AgeRestriction.vue'
import ContentPreference from '@/sections/ContentPreference.vue'
import FantasyPreference from '@/sections/FantasyPreference.vue'
import Approval from '../sections/Approval.vue'

export default {
  name:"NotSafeForWork1",
  components: { 
    AgeRestriction,
    ContentPreference,
    FantasyPreference,
    Approval,
  },
  data: function() {
    return {
      Step: "RestrictionForm",
      Mode: "dark",
      Type: "nfsw"
    }
  },
  computed:{
    RestrictionForm: function() {
      return this.Step === "RestrictionForm";
    },
    ContentPreferenceForm: function() {
      return this.Step === "ContentPreferenceForm";
    },
    FantasyPreferenceForm: function() {
      return this.Step === "FantasyPreferenceForm";
    },
    ApprovalForm: function() {
      return this.Step === "ApprovalForm";
    },
  },
  methods: {
    Next: function(nextSection){
      this.Step = nextSection;
    },
    OpenSite: function() {
      location.href =process.env.VUE_APP_URL_NOT_SAFE_FOR_WORK1
    }
  }
}
</script>
<style scoped>
  #notsafeforwork1 {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: url("../assets/images/background-default.jpg");
    background-size: cover;
  }
  .container{
    background: #FFF;
    border-radius: 15px;
    width: 500px;
    height: 700px;
    margin-right: 20%;
  }
  ::v-deep #contentpreference .action, ::v-deep #fantasypreference .action{
    display: inline-block;
    width: calc(50% - 37px);
    padding: 25px 0px 0px 25px;
  }
  ::v-deep #contentpreference button, ::v-deep #fantasypreference button{
    text-align: left;
    padding: 17px;
    width: 100%;
    min-width: 0px;
    background: #101010 0% 0% no-repeat padding-box;
  }
  ::v-deep #contentpreference button:hover, ::v-deep #fantasypreference button:hover{
    background: #FF4081;
  }
  @media (max-width: 767px) {
    #notsafeforwork1 {
      justify-content: center;
    }
    .container{
      margin: 0px;
      border-radius: 0px;
    }
    ::v-deep #contentpreference button, ::v-deep #fantasypreference button{
      font-size: 20px;
    }
  }
</style>