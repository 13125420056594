<template>
    <div id="fantasypreference">
        <mv-live-logo :mode='mode' :type='type' v-show="type==='nfsw'"/>
        <content-title>Choose your favorite:</content-title>
        <action-button 
            @click="$emit('click')" 
            v-for="preference in Preferences" 
            :key="preference"
            :icon="false"
            color="black"
        >{{preference}}</action-button>
    </div>
</template>
<script>
import ContentTitle from '@/components/ContentTitle'
import ActionButton from '@/components/ActionButton'
import MVLiveLogo from '@/components/MVLiveLogo'

export default {
    name:"FantasyPreference",
    data: function() {
        return {
            Preferences:[
                "ROLEPLAY",
                "CAM TO CAM",
                "TOYS SHOW",
                "SQUIRT SHOW"
            ]
        }  
    },
    components: {
        'mv-live-logo': MVLiveLogo,
        ContentTitle,
        ActionButton
    },
    props: {
        mode:String,
        type:String
    }
}
</script>