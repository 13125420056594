<template>
    <div id="mvlivelogo" :class="type">
        <img v-if="mode===undefined" src="../assets/images/mvlivelogo.svg"/>
        <img v-if="mode==='dark'" src="../assets/images/mvlivelogo-white.svg"/>
        <span :class="'title '+mode">MV LIVE</span>
    </div>
</template>
<script>
export default {
    name:"MVLiveLogo",
    props: ["mode","type"],
}
</script>
<style scoped>
    #mvlivelogo{
        display: flex;
        align-content: center;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        padding: 0px 0px 0px 0px;
        margin-top: 50px;
    }
    #mvlivelogo img{
        height: 69px;
        margin-right: 25px;
    }
    #mvlivelogo .title{
        font: normal normal bold 56px Prompt;
        color: #101010;
        line-height: 1;
    }
    #mvlivelogo .title.dark{
        color: #FFF;
    }

    /* nfsw */
    #mvlivelogo.nfsw img{
        height: 57px;
    }
    #mvlivelogo.nfsw .title{
        font-size: 46px;
    }


    #mvlivelogo.nfsw{
        padding: 90px 25px;
        background: #101010;
        margin: 25px 25px 0px 25px;
        border-radius: 15px;
    }
    @media (max-width: 767px) {
        #mvlivelogo.nfsw {
            padding: 50px 25px;
        }
    }
</style>