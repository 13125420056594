<template>
    <div :class="'content '+mode">
        <slot></slot>
    </div>
</template>
<script>
export default {
    name:"AdsContent",
    props: {
        mode:String
    },
}
</script>
<style scoped>
    .content{
        text-align: center;
        font: normal normal normal 32px/38px Roboto;
        letter-spacing: 0px;
        color: #101010;
        padding: 25px 50px;
    }
    .dark{
        color: #FFF;
    }
</style>