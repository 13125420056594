<template>
  <div id="safeforwork2">
    <div class="container">
      <age-restriction
        v-if="RestrictionForm"
        @click="Next('ContentPreferenceForm')"
        mode="dark"
        align="left"
      ></age-restriction>
      <content-preference 
        v-if="ContentPreferenceForm" 
        @click="Next('FantasyPreferenceForm')"
      ></content-preference>
      <fantasy-preference v-if="FantasyPreferenceForm" @click="Next('ApprovalForm')"></fantasy-preference>
      <approval v-if="ApprovalForm" @click="OpenSite"></approval>
    </div>
  </div>
</template>
<script>
import AgeRestriction from '@/sections/AgeRestriction.vue'
import ContentPreference from '@/sections/ContentPreference.vue'
import FantasyPreference from '@/sections/FantasyPreference.vue'
import Approval from '../sections/Approval.vue'

export default {
  name:"SafeForWork2",
  components: { 
    AgeRestriction,
    ContentPreference,
    FantasyPreference,
    Approval,
  },
  data: function() {
    return {
      Step: "RestrictionForm",
    }
  },
  computed:{
    RestrictionForm: function() {
      return this.Step === "RestrictionForm";
    },
    ContentPreferenceForm: function() {
      return this.Step === "ContentPreferenceForm";
    },
    FantasyPreferenceForm: function() {
      return this.Step === "FantasyPreferenceForm";
    },
    ApprovalForm: function() {
      return this.Step === "ApprovalForm";
    },
  },
  methods: {
    Next: function(nextSection){
      this.Step = nextSection;
    },
    OpenSite: function() {
      location.href =process.env.VUE_APP_URL_SAFE_FOR_WORK2
    }
  }
}
</script>
<style scoped>
  #safeforwork2 {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url("../assets/images/background-dark-gradient.jpg");
    background-size: cover;
    z-index: -2;
    overflow: hidden;
  }
  .container{
    border-radius: 15px;
    width: 500px;
    height: 700px;
    padding-top: 100px;
  }
  .container::after{
    top: 15%;
    right: -20%;
    width: 130%;
    height: 100%;
    transform: matrix(0.99, -0.12, 0.12, 0.99, 0, 0);
    background: #101010 0% 0% no-repeat padding-box;
    opacity: 0.8;
    position: absolute;
    content: "";
    z-index: -1;
  }

  ::v-deep .title{
    color: #FFF;
  }
  ::v-deep #agerestriction .content{
    color: #FFF;
  }
  ::v-deep #agerestriction button{
    margin: auto;
  }

  ::v-deep #contentpreference button, ::v-deep #fantasypreference button{
    padding: 17px 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: #767676;
  }
  ::v-deep #contentpreference button:hover, ::v-deep #fantasypreference button:hover{
    background: #B53BFE;
    color: #FFF;
  }
  ::v-deep #approval .content{
    color: #FFF;
  }

  @media (max-width: 767px) {
    #safeforwork2 {
      justify-content: center;
    }
    .container{
      margin: 0px;
      border-radius: 0px;
    }
    ::v-deep #agerestriction button{
      margin: auto;
    }
  }
</style>