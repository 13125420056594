<template>
    <div class="action">
        <button @click="$emit('click')">
            <font-awesome-icon v-if="AngleDoubleLeft" class="icon" icon="angle-double-right" />
            <span><slot></slot></span>
            <font-awesome-icon v-if="AngleDoubleRight" class="icon" icon="angle-double-left" />
        </button>
    </div>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleDoubleRight, faAngleDoubleLeft)

export default {
    name:"ActionButton",
    components: {
        'font-awesome-icon': FontAwesomeIcon,
    },
    props:{
        AngleDoubleRight: Boolean,
        AngleDoubleLeft: Boolean,
    }
}
</script>
<style scoped>
    .action{
        padding: 25px 25px 0px 25px;
    }
    button{
        font-family: "Roboto";
        cursor: pointer;
        background: var(---ff4081) 0% 0% no-repeat padding-box;
        background: #FF4081 0% 0% no-repeat padding-box;
        border-radius: 4px;
        font-size: 26px;
        padding: 17px 17px 17px 17px;
        color: #FFF;
        outline: none;
        font-weight: bold;
        border: none;
        text-align: right;
        transition: all 150ms ease-in-out;
        display: flex;
        margin: auto;
        justify-content: space-between;
        align-items: center;
        min-width: 300px;
    }
    button .icon{
        padding: 0px 10px;
        font-size: 38px;
    }
</style>