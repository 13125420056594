<template>
    <div class="loaders">
        <div v-if="Type==='circle'">
            <lottie-animation
                ref="Loader"
                class="loader"
                v-show="Processing"
                :autoPlay="false"
                :animationData="require('@/assets/images/loader-check.json')"
                @complete="complete" 
            />
            <lottie-animation
                ref="Check"
                class="loader"
                v-show="!Processing"
                :autoPlay="false"
                :animationData="require('@/assets/images/check-animated.json')"
            />
        </div>
        <div v-if="Type==='progressbar'">
            <lottie-animation
                ref="Loader"
                class="loader"
                v-show="Processing"
                :autoPlay="false"
                :animationData="require('@/assets/images/progressbar-purple.json')"
                @complete="complete" 
            />
        </div>
    </div>
</template>
<script>
import LottieAnimation from 'lottie-web-vue'

export default {
    name:"Loaders",
    data: function() {
        return {
            Loader:null,
            Check:null,
            Processing: true,
        }  
    },
    components: {
        LottieAnimation,
    },
    mounted: function(){
        this.$refs.Loader.play();
    },
    methods:{
        complete: function(){
            if(this.Type === "circle"){
                this.Processing = false;
                this.$refs.Check.play()
                setTimeout(() => {
                    this.$refs.Check.pause()
                    this.$emit('complete');
                },910)
            }else{
                this.Processing = false;
                this.$emit('complete');
            }
        }
    },
    props: {
        Type:{
            type: String,
            default: "circle"
        }
    }
}
</script>
<style scoped>
    .loaders{
        display: flex;
        justify-content: center;
        align-content: center;
    }
    .loaders .loader{
        width: 200px;
    }
</style>