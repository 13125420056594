<template>
  <div id="safeforwork4">
    <div class="container">
      <age-restriction
        v-if="RestrictionForm"
        @click="Next('SiteConfirmation')"
        mode="dark"
        align="left"
      ></age-restriction>
      <site-confirmation
        v-if="SiteConfirmation"
        @click="Next('ContentPreferenceForm')"
        mode="dark"
        align="left"
      ></site-confirmation>
      
      <content-preference 
        v-if="ContentPreferenceForm" 
        @click="Next('FantasyPreferenceForm')"
      ></content-preference>
      <fantasy-preference v-if="FantasyPreferenceForm" @click="Next('ApprovalForm')"></fantasy-preference>
      <approval v-if="ApprovalForm" @click="OpenSite"></approval>
    </div>
  </div>
</template>
<script>
import AgeRestriction from '@/sections/AgeRestriction.vue'
import SiteConfirmation from '@/sections/SiteConfirmation.vue'
import ContentPreference from '@/sections/ContentPreference.vue'
import FantasyPreference from '@/sections/FantasyPreference.vue'
import Approval from '../sections/Approval.vue'

export default {
  name:"SafeForWork4",
  components: { 
    AgeRestriction,
    SiteConfirmation,
    ContentPreference,
    FantasyPreference,
    Approval,
  },
  data: function() {
    return {
      Step: "RestrictionForm",
    }
  },
  computed:{
    RestrictionForm: function() {
      return this.Step === "RestrictionForm";
    },
    SiteConfirmation: function() {
      return this.Step === "SiteConfirmation";
    },
    ContentPreferenceForm: function() {
      return this.Step === "ContentPreferenceForm";
    },
    FantasyPreferenceForm: function() {
      return this.Step === "FantasyPreferenceForm";
    },
    ApprovalForm: function() {
      return this.Step === "ApprovalForm";
    },
  },
  methods: {
    Next: function(nextSection){
      this.Step = nextSection;
    },
    OpenSite: function() {
      location.href =process.env.VUE_APP_URL_SAFE_FOR_WORK4
    }
  }
}
</script>
<style scoped>
  #safeforwork4 {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #303030;
    background-size: cover;
    z-index: -2;
    overflow: hidden;
  }
  .container{
    position: absolute;
    top: 0px;
    border-radius: 15px;
    width: 700px;
    height: 700px;
    padding-top: 100px;
  }
  .container::before{
    width: 1200px;
    height: 1200px;
    position: absolute;
    content: "";
    z-index: -2;
    top: 0px;
    background: transparent url('../assets/images/background-flip1.png') no-repeat padding-box;
    background-size: cover;
    margin-left: -825px;
    margin-top: -125px;
  }
  .container::after{
    width: 750px;
    height: 750px;
    position: absolute;
    content: "";
    z-index: -2;
    background: transparent url('../assets/images/background-flip2.png') no-repeat padding-box;
    background-size: cover;
    top: 0px;
    margin-left: 900px;
    margin-top: -100px;
  }
  ::v-deep #mvlivelogo{
    justify-content: center;
  }
  ::v-deep .title{
    color: #FFF;
  }

  /* age restriction */
  ::v-deep #agerestriction{
    position: absolute;
    width: 700px;
    height: 700px;
  }
  ::v-deep #agerestriction .content{
    color: #FFF;
  }
  ::v-deep #agerestriction button{
    margin: auto;
  }

  /* site confirmation */
  ::v-deep #siteconfirmation{
    position: absolute;
    width: 700px;
    height: 700px;
  }

  ::v-deep #siteconfirmation .content{
    text-align: left;
    color: #FFF;
  }
  ::v-deep #siteconfirmation button{
    margin: 0px 0px 0px 25px;
  }

  /* content preference */
  ::v-deep #contentpreference button, ::v-deep #fantasypreference button{
    padding: 17px 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: #767676;
  }
  ::v-deep #contentpreference button:hover, ::v-deep #fantasypreference button:hover{
    background: #B53BFE;
    color: #FFF;
  }
  ::v-deep #approval .content{
    color: #FFF;
  }


  /* transparent background */
  ::v-deep #siteconfirmation button{
      width: 400px;
  }
  ::v-deep #siteconfirmation::before, ::v-deep #contentpreference::before, ::v-deep #fantasypreference::before, ::v-deep #approval::before{
    content: "";
    display: block;
    background: #303030;
    opacity: 0.8;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0px;
    left: 0px;;
    z-index: -1;
  }

  @media screen 
    and (min-device-width: 1600px) {
      ::v-deep #agerestriction{
        margin-left: 300px;
      }
  }


  /* rules for ipad portrait */
  @media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
    .container::before{
      margin-top: 600px;
      margin-left: -250px;
    }
    .container::after{
      margin-left: 350px;
      margin-top: -150px;
    }
  }
  /* rules for ipad landscape */
  @media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
    .container::before{
      margin-left: -980px;
    }
    .container::after{
      margin-left: 450px;
      margin-top: -150px;
    }
  }

  @media (max-width: 767px) {
    ::v-deep #mvlivelogo{
      justify-content: center;
      margin: 0px;
    }
    #safeforwork4 {
      justify-content: center;
    }
    .container{
      margin: 0px;
      border-radius: 0px;
      width: 100%;
    }
    ::v-deep .title{
      margin-top: 0px;
    }
    ::v-deep #agerestriction{
      width: 100%;
    }
    ::v-deep #agerestriction button{
      margin: auto;
    }
    ::v-deep #siteconfirmation{
      width: 100%;
      text-align: center;
    }
    ::v-deep #siteconfirmation .action{
      padding:  0px 25px;
    }
    ::v-deep #siteconfirmation button{
      width: auto;
    }
  }
</style>
    