<template>
  <div id="home">
    <mv-live-logo/>
  </div>
</template>

<script>
import MVLiveLogo from '@/components/MVLiveLogo'

export default {
  name: 'Home',
  components: {
    "mv-live-logo": MVLiveLogo,
  }
}
</script>

<style scoped>
#home{
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:center;
}
</style>