import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import SafeForWork1 from '../views/SafeForWork1.vue'
import SafeForWork2 from '../views/SafeForWork2.vue'
import SafeForWork3 from '../views/SafeForWork3.vue'
import SafeForWork4 from '../views/SafeForWork4.vue'
import NotSafeForWork1 from '../views/NotSafeForWork1.vue'
import NotSafeForWork2 from '../views/NotSafeForWork2.vue'
import NotSafeForWork3 from '../views/NotSafeForWork3.vue'
import NotSafeForWork4 from '../views/NotSafeForWork4.vue'

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }
]
switch(process.env.VUE_APP_ADS_TYPE) {
  case 'SFW':
    routes = routes.concat([
      {
        path: '/fpi1',
        name: 'Safe For Work 1',
        component: SafeForWork1
      },
      {
        path: '/fpi2',
        name: 'Safe For Work 2',
        component: SafeForWork2
      },
      {
        path: '/fpi3',
        name: 'Safe For Work 3',
        component: SafeForWork3
      },
      {
        path: '/fpi4',
        name: 'Safe For Work 4',
        component: SafeForWork4
      },
    ])
    break;
  case 'NSFW':
      routes = routes.concat([
        {
          path: '/fpi1',
          name: 'Not Safe For Work 1',
          component: NotSafeForWork1
        },
        {
          path: '/fpi2',
          name: 'Not Safe For Work 2',
          component: NotSafeForWork2
        },
        {
          path: '/fpi3',
          name: 'Not Safe For Work 3',
          component: NotSafeForWork3
        },
        {
          path: '/fpi4',
          name: 'Not Safe For Work 4',
          component: NotSafeForWork4
        },
      ])
      break;
  default:
    break;
}
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
