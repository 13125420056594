<template>
    <div id="agerestriction">
        <mv-live-logo :mode='mode' :type='type'/>
        <ads-content>
            <p>Join the MV Live Fun</p>
            <p><b>Are you 18+?</b></p>
        </ads-content>
        <action-button  
            @click="$emit('click')"
            :AngleDoubleLeft="true"
        >ENTER</action-button>
    </div>
</template>
<script>
import MVLiveLogo from '@/components/MVLiveLogo'
import ActionButton from '@/components/ActionButton'
import AdsContent from '@/components/Content'

export default {
    name:"AgeRestriction",
    components: {
        "mv-live-logo": MVLiveLogo,
        ActionButton,
        AdsContent
    },
    props: {
        mode:String,
        type:String
    }
}
</script>
<style scoped>
    ::v-deep .action button{
        padding: 17px 35px 17px 17px;
    }
   
    ::v-deep .action button:hover{
        background: #B53BFE;
        padding-right: 145px;
    }
    
</style>