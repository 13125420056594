<template>
  <div id="safeforwork3">
    <div class="container">
      <age-restriction
        v-if="RestrictionForm"
        @click="Next('ContentPreferenceForm')"
        mode="dark"
        align="left"
      ></age-restriction>
      <content-preference 
        v-if="ContentPreferenceForm" 
        @click="Next('FantasyPreferenceForm')"
      ></content-preference>
      <fantasy-preference v-if="FantasyPreferenceForm" @click="Next('ApprovalForm')"></fantasy-preference>
      <approval v-if="ApprovalForm" @click="OpenSite"></approval>
    </div>
  </div>
</template>
<script>
import AgeRestriction from '@/sections/AgeRestriction.vue'
import ContentPreference from '@/sections/ContentPreference.vue'
import FantasyPreference from '@/sections/FantasyPreference.vue'
import Approval from '../sections/Approval.vue'

export default {
  name:"SafeForWork3",
  components: { 
    AgeRestriction,
    ContentPreference,
    FantasyPreference,
    Approval,
  },
  data: function() {
    return {
      Step: "RestrictionForm",
    }
  },
  computed:{
    RestrictionForm: function() {
      return this.Step === "RestrictionForm";
    },
    ContentPreferenceForm: function() {
      return this.Step === "ContentPreferenceForm";
    },
    FantasyPreferenceForm: function() {
      return this.Step === "FantasyPreferenceForm";
    },
    ApprovalForm: function() {
      return this.Step === "ApprovalForm";
    },
  },
  methods: {
    Next: function(nextSection){
      this.Step = nextSection;
    },
    OpenSite: function() {
      location.href =process.env.VUE_APP_URL_SAFE_FOR_WORK3
    }
  }
}
</script>
<style scoped>
  #safeforwork3 {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    background: url("../assets/images/background-dark.jpg");
    background-size: cover;
    z-index: -2;
    overflow: hidden;
  }
  .container{
    border-radius: 15px;
    width: 500px;
    height: 700px;
    padding-top: 150px;
  }
  .container::before{
    width: 1000px;
    height: 1000px;
    position: absolute;
    content: "";
    z-index: -1;
    top: 0%;
    background: transparent url('../assets/images/background-splash-1.svg') no-repeat padding-box;
    background-size: cover;
    margin-left: -500px;
    margin-top: -100px;
  }
  .container::after{
    width: 1000px;
    height: 1000px;
    position: absolute;
    content: "";
    z-index: -1;
    top: 0%;
    background: transparent url('../assets/images/background-splash-2.svg') no-repeat padding-box;
    background-size: cover;
    margin-left: -100px;
    margin-top: 10px;
  }
  
  ::v-deep .title{
    color: #FFF;
  }
  ::v-deep #agerestriction .content{
    color: #FFF;
  }

  ::v-deep #contentpreference button, ::v-deep #fantasypreference button{
    padding: 17px 30px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    color: #767676;
  }
  ::v-deep #contentpreference button:hover, ::v-deep #fantasypreference button:hover{
    background: #B53BFE;
    color: #FFF;
  }
  ::v-deep #approval .content{
    color: #FFF;
  }

  @media (max-width: 767px) {
    ::v-deep #mvlivelogo{
      justify-content: center;
      margin: 0px;
    }
    #safeforwork3 {
      justify-content: center;
    }
    .container{
      margin: 0px;
      border-radius: 0px;
      padding-top: 100px;
    }
    ::v-deep #agerestriction button{
      margin: auto;
    }
  }
</style>