<template>
    <div id="contentpreference">
        <mv-live-logo :mode='mode' :type='type' v-show="type==='nfsw'"/>
        <content-title>Choose a hair color:</content-title>
        <action-button 
            @click="$emit('click')" 
            v-for="preference in Preferences" 
            :key="preference"
            :icon="false"
        >{{preference}}</action-button>
    </div>
</template>
<script>
import ContentTitle from '@/components/ContentTitle'
import ActionButton from '@/components/ActionButton'
import MVLiveLogo from '@/components/MVLiveLogo'

export default {
    name:"ContentPreference",
    data: function() {
        return {
            Preferences:[
                "BLONDE",
                "BRUNETTE",
                "REDHEAD",
                "BLACK"
            ]
        }  
    },
    components: {
        'mv-live-logo': MVLiveLogo,
        ContentTitle,
        ActionButton,
    },
    props: {
        mode:String,
        type:String
    }
}
</script>