<template>
    <div id="approval">
        <mv-live-logo :mode='mode' :type='type' v-if="type==='nfsw'"/>
        <loaders @complete="complete" v-if="type!=='nfsw'"></loaders>
        <ads-content class="content" v-show="type!=='nfsw'||(type==='nfsw'&&!Processing)">
            <p>Analyzing…</p>
            <p>Country and location detection…</p>
            <p>Account approved…</p>
            <p>Searching…</p>
        </ads-content>
        <loaders @complete="complete" type="progressbar" v-if="type==='nfsw'"></loaders>
        <transition name="fade">
            <action-button 
                v-show="!Processing"
                @click="$emit('click')"
                :AngleDoubleRight="true"
                :AngleDoubleLeft="true"
            >CREATE YOUR FREE ACCOUNT</action-button>
        </transition>
    </div>
</template>
<script>
import Loaders from '../components/Loaders.vue'
import AdsContent from '../components/Content'
import ActionButton from '../components/ActionButton'
import MVLiveLogo from '@/components/MVLiveLogo'

export default {
    name:"Approval",
    data: function() {
        return {
            Preferences:[
                "ROLEPLAY",
                "CAM TO CAM",
                "TOYS SHOW",
                "SQUIRT SHOW"
            ],
            Processing: true,
        }  
    },
    components: {
        'mv-live-logo': MVLiveLogo,
        Loaders,
        AdsContent,
        ActionButton
    },
    methods:{
        complete: function(){
            this.Processing = false;
        }
    },
    props: {
        mode:String,
        type:String
    }
}
</script>
<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .content{
        padding: 0px;
    }
    .content p{
        margin:15px 0px
    }
    ::v-deep button{
        text-align: center;
    }
</style>