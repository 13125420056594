<template>
    <div id="siteconfirmation">
        <mv-live-logo :mode='mode' :type='type'/>
        <ads-content>
            <p>This cam site is very addictive as you can live your craziest fantasies with the most amazing live cam girls in the world</p>
        </ads-content>
        <action-button  
            @click="$emit('click')"
            :AngleDoubleLeft="true"
        >OK CONTINUE</action-button>
    </div>
</template>
<script>
import MVLiveLogo from '@/components/MVLiveLogo'
import ActionButton from '@/components/ActionButton'
import AdsContent from '@/components/Content'

export default {
    name:"SiteConfirmation",
    components: {
        "mv-live-logo": MVLiveLogo,
        ActionButton,
        AdsContent
    },
    props: {
        mode:String,
        type:String
    }
}
</script>
<style scoped>
    ::v-deep .action button{
        padding: 17px 35px 17px 17px;
    }
   
    ::v-deep .action button:hover{
        background: #B53BFE;
        padding-right: 145px;
    }
    
</style>