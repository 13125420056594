<template>
    <div class="title">
        <font-awesome-icon class="icon" icon="angle-double-right" /> 
        <slot></slot>
    </div>
</template>
<script>
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleDoubleRight)

export default {
    name:"ContentTitle",
    components: {
        'font-awesome-icon': FontAwesomeIcon,
    },
}
</script>
<style scoped>
    .title{
        font-size: 32px;
        display: flex;
        padding: 25px 50px;
        margin-top: 50px;
    }
    .title > .icon{
        color: #FF4081;
        padding-right: 15px;
        font-size: 38px;
    }
</style>